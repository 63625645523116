<template>
    <div class="receiveInterview-container">
        <BreadcrumbNav :page-name="$t('myInterview.myInterviewDetail')"/>
        <div class="main">
            <div class="people-wrap" v-if="details_data.is_hidden_issuer == '1'">
                <div class="people-info fl">
                    <img :src="details_data.initiator_avatar" alt="" class="head-icon fl">
                    <div class="people-info-text fl">
                        <h1 class="over">{{ details_data.initiator_name }}</h1>
                        <h2 class="over">{{ details_data.company_name }} · {{ details_data.position }}</h2>
                    </div>
                </div>
                <div class="btn-group fl">
                    <CustomButton @click.native="$router.push({
                        path: '/user/index',query:{userType:2, talentId:details_data.initiator_id,}})" class="fl"
                                  type="black">
                        {{ $t("project.buyNow") }}
                    </CustomButton>
                    <CustomButton @click.native="attention(2, details_data.initiator_id,  details_data.initiator_name) "
                                  class="fl" style="margin-left: 13px" type="white"
                                  v-if="details_data.followed == 1">
                        {{ $t("myProject.unfollow") }}
                    </CustomButton>
                    <CustomButton @click.native="attention(1, details_data.initiator_id,  details_data.initiator_name) "
                                  class="fl" style="margin-left: 13px" type="white-line"
                                  v-else>
                        {{ $t("myProject.immediateAttention") }}
                    </CustomButton>
                </div>
            </div>
            <!--------------------待受邀人反馈 五选一START---------------->
            <div class="state6" v-if="details_data.progress==1">
                <AppointmentTime :times1="times1" :type="0"/>
            </div>
            <!--------------------待受邀人反馈 五选一END-------------------->
            <!--------------------受邀人拒绝START---------------->
            <div class="state6" v-if="details_data.progress==2">
                <h1>{{$t("myPublishedProjects.declinedInvitation")}}<!--您已拒绝邀请--></h1>
                <p>{{$t("myPublishedProjects.reasonInvitation")}}<!--拒绝邀请原因--> ：{{ details_data.untitled }}</p>
            </div>
            <!--------------------受邀人拒绝END-------------------->
            <!--------------------受邀人同意START---------------->
            <div class="state6" v-if="details_data.progress==3">
                <AppointmentTime :parent-data="details_data" :times1="times1" :type="1"/>
            </div>
            <!--------------------受邀人同意END-------------------->
            <!--------------------发起人取消START---------------->
            <div class="state6" v-if="details_data.progress==4">
                <AppointmentTime :parent-data="details_data" :type="8"/>
            </div>
            <!--------------------发起人取消END-------------------->
            <!--------------------受邀人取消START---------------->
            <div class="state6" v-if="details_data.progress==5">
                <AppointmentTime :parent-data="details_data" :type="6"/>
            </div>
            <!--------------------受邀人取消END-------------------->
            <!--------------------待评价START---------------->
            <div class="state6" v-show="details_data.progress==6&&details_data.is_evaluate==0">
                <AppointmentTime :parent-data="details_data" :type="4"/>
            </div>
            <!--------------------待评价END-------------------->
            <!--------------------已评价START---------------->
            <div class="state6" v-show="details_data.progress==6&&details_data.is_evaluate==1">
                <AppointmentTime :parent-data="details_data" :type="3"/>
            </div>
            <!--------------------已评价END-------------------->
            <!--    沟通话题-->
            <div class="talk-topic">
                <h1>{{$t("myPublishedProjects.communTopic")}}<!--沟通话题--></h1>
                <img :src="details_data.project_cover_url" alt="">
                <div class="title over">{{ details_data.project_name }}</div>
                <p>{{ details_data.project_intro }}</p>
                <div class="source">
                    <span>{{ details_data.financing_stage }}</span>
                </div>
            </div>
            <!--      项目详情-->
            <div class="project-detail">
                <h1>{{$t('project.details')}}<!--项目详情--></h1>
                <div style="padding-top: 20px" v-html="details_data.details"></div>
            </div>
        </div>

    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import AppointmentTime from "@/components/my_project/my_interview/receive_interview/AppointmentTime";
    import RecommendReason from "@/components/my_project/my_interview/receive_interview/RecommendReason";
    import {selectBuyThreeInviteById} from "@/api/myProjectApi/interviewApi"
    import {followTalent} from "@/api/memberApi";

    export default {
        name: "receiveInterview",
        data() {
            return {
                delivery_data: {},
                details_data: {},
                times1: {},
            };
        },
        components: {
            BreadcrumbNav,
            CustomButton,
            AppointmentTime,
            RecommendReason
        },
        props: {},
        mounted() {
            this.delivery_data = this.$route.query;
            this.getPageData();
        },
        methods: {
            // 页面进度数据
            async getPageData() {
                let formData = {
                    projectId: this.$route.params.id,
                    token: localStorage.getItem("token"),
                };
                let res = await selectBuyThreeInviteById(formData);
                if (res.code == 200) {
                    this.details_data = res.data;
                    this.times1 = {
                        time1: this.details_data.invite_time1,
                        time2: this.details_data.invite_time2,
                        time3: this.details_data.invite_time3,
                        time4: this.details_data.invite_time4,
                        time5: this.details_data.invite_time5,
                    };
                } else {
                    this.$message.error(res.msg);
                }
            },
            //关注
            attention(type, id, name) {
                let formData = {
                    operateType: type,
                    talentId: id,
                    talentName: name,
                    token: localStorage.getItem('token')
                }
                followTalent(formData).then((res) => {
                    if (res.code == 200) {
                        if (type == 1) {
                            this.details_data.followed = 1
                            this.$message.success(this.$t("myProject.followMsg.followSuccess"))
                        } else {
                            this.details_data.followed = 0
                            this.$message.success(this.$t("myProject.followMsg.unfollowSuccess"))
                        }
                        this.$forceUpdate()
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .state6 h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .state6 p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        margin-top: 10px;
    }

    .receiveInterview-container {
        background-color: #FFFFFF;
    }

    .main {
        width: 1200px;
        padding-top: 14px;
        padding-bottom: 24px;
        margin: 0 auto;
    }

    .people-wrap {
        padding: 38px 0;
        overflow: hidden;
    }

    .people-wrap .head-icon {
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-info-text {
        margin-left: 13px;
        min-width: 160px;
        max-width: 300px;
    }

    .people-info-text > h1 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-top: 8px;
    }

    .people-info-text > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-top: 13px;
        margin-left: 4px;
    }

    .people-wrap .btn-group {
        margin-top: 4px;
        margin-left: 136px;
    }

    hr {
        background-color: #E5E5E5;;
    }

    .talk-topic {
        margin-top: 62px;
    }

    .talk-topic > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .talk-topic > img {
        width: 695px;
        height: 460px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
        margin-top: 24px;
    }

    .talk-topic > .title {
        width: 695px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 46px;
        color: #000000;
        margin-top: 24px;
    }

    .talk-topic > p {
        width: 468px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 29px;
        color: #000000;
        margin-top: 26px;
    }

    .talk-topic > .source {
        margin-top: 16px;
    }

    .talk-topic > .source > span:first-child {
        font-size: 22px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        vertical-align: bottom;
        margin-right: 9px;
    }

    .talk-topic > .source > span:nth-child(2) {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: bottom;
    }

    .project-detail {
        margin-top: 47px;
    }

    .project-detail > h1 {

        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .project-detail > img {
        margin-top: 42px;

        width: 688px;
    }

    .reason {
        margin-top: 16px;
    }

    .reason > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .reason > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
    }

    .is-btn-group {
        margin-top: 38px;
        overflow: hidden;
    }
</style>
